import { Fragment as _Fragment, jsx as _jsx } from "react/jsx-runtime";
import { useTranslation } from "react-i18next";
import dayjs from "dayjs";
import "dayjs/locale/ru";
import "@src/i18n/config";
import { useEffect } from "react";
export var LocalizationProvider = function (_a) {
    var children = _a.children;
    var i18n = useTranslation("common").i18n;
    dayjs.locale(i18n.language);
    useEffect(function () {
        dayjs.locale(i18n.language);
    }, [i18n.language]);
    return _jsx(_Fragment, { children: children });
};
