// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.QlHRCYFuzpgxbzBzfqdK {
  display: flex;
  align-items: center;
  flex-direction: row;
  border-bottom: 1px solid #e0e0e0;
  min-height: 65px;
}

.QlHRCYFuzpgxbzBzfqdK:first-child {
  padding-top: 0;
  /* margin-top: 10px; */
}

.QlHRCYFuzpgxbzBzfqdK:last-child {
  margin-bottom: 0;
  border-bottom: none;
}

.wO9Dgj_gty8ZkPO5bAOb {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  color: #000000;
  cursor: pointer;
}

.X16b5o2vKaxIAFmUulQz {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #4f4f4f;
}

.rJhGGWJ_Tk4XQxGW5s9j {
  width: 44px;
  height: 44px;
  margin-right: 3%;
  border-radius: 100%;
  cursor: pointer;
}

._5Br2BVHSVU1Mn7CdaLU {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  /* border-bottom: 1px solid #e0e0e0; */
}

.QObyuhLKeXAI8TTa8dJh {
  display: flex;
  flex-direction: column;
}

.T2pAAJSaQ5UG2a3Edxdk {
  height: 20px;
  cursor: pointer;
}`, "",{"version":3,"sources":["webpack://./src/pages/CompaniesSearchPage/CompaniesSerchResult/cardCompany/cardCompany.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,mBAAmB;EACnB,mBAAmB;EACnB,gCAAgC;EAChC,gBAAgB;AAClB;;AAEA;EACE,cAAc;EACd,sBAAsB;AACxB;;AAEA;EACE,gBAAgB;EAChB,mBAAmB;AACrB;;AAEA;EACE,qBAAqB;EACrB,kBAAkB;EAClB,gBAAgB;EAChB,eAAe;EACf,iBAAiB;EACjB,cAAc;EACd,eAAe;AACjB;;AAEA;EACE,qBAAqB;EACrB,kBAAkB;EAClB,gBAAgB;EAChB,eAAe;EACf,iBAAiB;EACjB,cAAc;AAChB;;AAEA;EACE,WAAW;EACX,YAAY;EACZ,gBAAgB;EAChB,mBAAmB;EACnB,eAAe;AACjB;;AAEA;EACE,aAAa;EACb,8BAA8B;EAC9B,mBAAmB;EACnB,WAAW;EACX,sCAAsC;AACxC;;AAEA;EACE,aAAa;EACb,sBAAsB;AACxB;;AAEA;EACE,YAAY;EACZ,eAAe;AACjB","sourcesContent":[".cardCompanyBlock {\r\n  display: flex;\r\n  align-items: center;\r\n  flex-direction: row;\r\n  border-bottom: 1px solid #e0e0e0;\r\n  min-height: 65px;\r\n}\r\n\r\n.cardCompanyBlock:first-child {\r\n  padding-top: 0;\r\n  /* margin-top: 10px; */\r\n}\r\n\r\n.cardCompanyBlock:last-child {\r\n  margin-bottom: 0;\r\n  border-bottom: none;\r\n}\r\n\r\n.cardCompamyTittle {\r\n  font-family: \"Roboto\";\r\n  font-style: normal;\r\n  font-weight: 600;\r\n  font-size: 16px;\r\n  line-height: 20px;\r\n  color: #000000;\r\n  cursor: pointer;\r\n}\r\n\r\n.cardCompanySubtitle {\r\n  font-family: \"Roboto\";\r\n  font-style: normal;\r\n  font-weight: 400;\r\n  font-size: 14px;\r\n  line-height: 20px;\r\n  color: #4f4f4f;\r\n}\r\n\r\n.imgCompany {\r\n  width: 44px;\r\n  height: 44px;\r\n  margin-right: 3%;\r\n  border-radius: 100%;\r\n  cursor: pointer;\r\n}\r\n\r\n.infoWrapper {\r\n  display: flex;\r\n  justify-content: space-between;\r\n  align-items: center;\r\n  width: 100%;\r\n  /* border-bottom: 1px solid #e0e0e0; */\r\n}\r\n\r\n.companyNameAndStatus {\r\n  display: flex;\r\n  flex-direction: column;\r\n}\r\n\r\n.icon_follow_and_unfollow {\r\n  height: 20px;\r\n  cursor: pointer;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"cardCompanyBlock": `QlHRCYFuzpgxbzBzfqdK`,
	"cardCompamyTittle": `wO9Dgj_gty8ZkPO5bAOb`,
	"cardCompanySubtitle": `X16b5o2vKaxIAFmUulQz`,
	"imgCompany": `rJhGGWJ_Tk4XQxGW5s9j`,
	"infoWrapper": `_5Br2BVHSVU1Mn7CdaLU`,
	"companyNameAndStatus": `QObyuhLKeXAI8TTa8dJh`,
	"icon_follow_and_unfollow": `T2pAAJSaQ5UG2a3Edxdk`
};
export default ___CSS_LOADER_EXPORT___;
