var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
import { CardCompany } from "./cardCompany/cardCompany";
import { SearchErrorBlock } from "../../../components/SearchErrorBlock/SearchErrorBlock";
import style from "./companiesSeractResultBlock.module.css";
export var ConpaniesSearchResult = function (props) {
    var data = props.data;
    return (_jsx(_Fragment, { children: (data === null || data === void 0 ? void 0 : data.length) !== 0 ? (_jsx("div", __assign({ className: style.serch_result_block }, { children: (data === null || data === void 0 ? void 0 : data.pages) &&
                data.pages.map(function (page) {
                    var _a;
                    return (page === null || page === void 0 ? void 0 : page.results) &&
                        ((_a = page === null || page === void 0 ? void 0 : page.results) === null || _a === void 0 ? void 0 : _a.map(function (company, index) {
                            return _jsx(CardCompany, { company: company }, index);
                        }));
                }) }))) : (_jsx(SearchErrorBlock, {})) }));
};
