var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useNavigate } from "react-router-dom";
import { NEW_URL, followOrUnFollowAPI } from "../../../../api/API";
import { useAppDispatch } from "../../../../redux/store";
import follow_icon from "../../../../assets/img/new_icon_follow.svg";
import unsubscribe_icon from "../../../../assets/img/new_icon_unfollow.svg";
import style from "./cardCompany.module.css";
import { useMutation, useQueryClient } from "react-query";
import { useSelector } from "react-redux";
import { setActive, setAuthModalOpenStatus, setErrorMessageToUserForModal, setModal } from "../../../../redux/modalReducer";
import { useRedirectToProfileDependsOnWhoYouAre } from "../../../../components/RedirectToProfileDependsOnWhoYouAre/RedurectToProfilesForCreator";
export var CardCompany = function (_a) {
    var company = _a.company;
    var dispatch = useAppDispatch();
    var navigate = useNavigate();
    var queryClient = useQueryClient();
    var checkIsUserAuth = useSelector(function (state) { return state.auth.isAuth; });
    var redirectToProfile = useRedirectToProfileDependsOnWhoYouAre(company === null || company === void 0 ? void 0 : company.type, company === null || company === void 0 ? void 0 : company.id, company === null || company === void 0 ? void 0 : company.id_full, company === null || company === void 0 ? void 0 : company.visible_menu);
    var handleCheckDisplayedUserName = function (typeUser) {
        switch (typeUser) {
            case "expertProfile/":
                return "".concat(company === null || company === void 0 ? void 0 : company.first_name, " ").concat(company === null || company === void 0 ? void 0 : company.last_name);
            case "companyProfile/":
                return "".concat(company === null || company === void 0 ? void 0 : company.name);
            case "userProfile/":
                return "".concat(company === null || company === void 0 ? void 0 : company.name);
        }
    };
    // ------------------------------------- mutations --------------------------------//
    var unsubscribeFromCompamy = useMutation({
        mutationFn: function (id) { return followOrUnFollowAPI.unsubscribeOnCompany(id); },
        onSuccess: function () {
            //обновляются данные в разделе закладок
            Promise.all([
                queryClient.invalidateQueries("fetchPublicationOnMainPage"),
                queryClient.invalidateQueries("searchCompanyExperts"),
                queryClient.invalidateQueries("BookmarkedPublicationInProfile"),
                queryClient.invalidateQueries("BookmarkedEventsInProfile"),
                queryClient.invalidateQueries("fetchEventsOnEventsPage"),
                queryClient.invalidateQueries("fetchPublicationsOnCompanyPage"),
                queryClient.invalidateQueries("fetchArticlesOnArticlesPage"),
                queryClient.invalidateQueries("PublicationsInProfile"),
                queryClient.invalidateQueries("EventsInProfile"),
            ]);
        },
        onError: function (err) {
            var _a;
            if (err) {
                dispatch(setErrorMessageToUserForModal((_a = err === null || err === void 0 ? void 0 : err.response) === null || _a === void 0 ? void 0 : _a.data));
                dispatch(setModal("error-alert-modal"));
                dispatch(setActive(true));
            }
            console.log("error invalidate bookmark", err);
        },
    });
    var subscribeToCompamy = useMutation({
        mutationFn: function (id) { return followOrUnFollowAPI.subscribeOnCompany(id); },
        onSuccess: function () {
            //обновляются данные в разделе закладок
            Promise.all([
                queryClient.invalidateQueries("fetchPublicationOnMainPage"),
                queryClient.invalidateQueries("searchCompanyExperts"),
                queryClient.invalidateQueries("BookmarkedPublicationInProfile"),
                queryClient.invalidateQueries("BookmarkedEventsInProfile"),
                queryClient.invalidateQueries("fetchEventsOnEventsPage"),
                queryClient.invalidateQueries("fetchPublicationsOnCompanyPage"),
                queryClient.invalidateQueries("fetchArticlesOnArticlesPage"),
                queryClient.invalidateQueries("PublicationsInProfile"),
                queryClient.invalidateQueries("EventsInProfile"),
                queryClient.invalidateQueries("FollowsInProfile"),
            ]);
        },
        onError: function (err) {
            var _a;
            if (err) {
                dispatch(setErrorMessageToUserForModal((_a = err === null || err === void 0 ? void 0 : err.response) === null || _a === void 0 ? void 0 : _a.data));
                dispatch(setModal("error-alert-modal"));
                dispatch(setActive(true));
            }
            console.log("error invalidate bookmark", err);
        },
    });
    // ------------------------------------- mutatuions ------------------------------//
    //----------------------------------------- handlers --------------------------------//
    var handleUnsubscribeFromUser = function (id) {
        if (checkIsUserAuth === false || !checkIsUserAuth) {
            dispatch(setAuthModalOpenStatus({ isOpen: true }));
            return;
        }
        unsubscribeFromCompamy.mutate(id);
    };
    var handleSubscribeToUser = function (id) {
        if (checkIsUserAuth === false || !checkIsUserAuth) {
            dispatch(setAuthModalOpenStatus({ isOpen: true }));
            return;
        }
        subscribeToCompamy.mutate(id);
    };
    //----------------------------------------- handlers ---------------------------------//
    return (_jsxs("div", __assign({ className: style.cardCompanyBlock }, { children: [_jsx("img", { onClick: function () { return navigate(redirectToProfile); }, className: style.imgCompany, src: "".concat(NEW_URL).concat(company.avatar), alt: "company avatar" }), _jsxs("div", __assign({ className: style.infoWrapper }, { children: [_jsxs("div", __assign({ className: style.companyNameAndStatus }, { children: [_jsx("p", __assign({ onClick: function () { return navigate(redirectToProfile); }, className: style.cardCompamyTittle }, { children: handleCheckDisplayedUserName(company === null || company === void 0 ? void 0 : company.type) })), _jsx("p", __assign({ className: style.cardCompanySubtitle }, { children: company.status }))] })), (company === null || company === void 0 ? void 0 : company.follow) ? (_jsx("img", { onClick: function () {
                            handleUnsubscribeFromUser(company.id);
                        }, src: unsubscribe_icon, className: style.icon_follow_and_unfollow, alt: "un_follow company" })) : (_jsx("img", { onClick: function () {
                            handleSubscribeToUser(company.id);
                        }, src: follow_icon, className: style.icon_follow_and_unfollow, alt: "follow company" }))] }))] })));
};
