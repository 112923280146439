var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import App from "./App";
import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";
import { store } from "./redux/store";
import { GoogleOAuthProvider } from "@react-oauth/google";
import { QueryClient, QueryClientProvider } from "react-query";
import { ReactQueryDevtools } from "react-query/devtools";
import { LocalizationProvider } from "@src/providers";
import { HelmetProvider } from "react-helmet-async";
import "process/browser";
import { createRoot } from "react-dom/client";
// const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);
var rootElement = document.getElementById("root");
var container = document.getElementById("root");
var root = createRoot(container);
var queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            // staleTime: 1000 * 20,
            refetchOnMount: "always",
            refetchOnWindowFocus: "always",
        },
    },
});
if (rootElement === null || rootElement === void 0 ? void 0 : rootElement.hasChildNodes()) {
    root.render(_jsx(BrowserRouter, { children: _jsxs(QueryClientProvider, __assign({ client: queryClient }, { children: [_jsx(GoogleOAuthProvider, __assign({ clientId: "981924406162-llg4arbcva1i69uao2u6l29ajjo26lpj.apps.googleusercontent.com" }, { children: _jsx(Provider, __assign({ store: store }, { children: _jsx(LocalizationProvider, { children: _jsx(HelmetProvider, { children: _jsx(App, {}) }) }) })) })), _jsx(ReactQueryDevtools, { initialIsOpen: true })] })) }));
}
else {
    root.render(_jsx(BrowserRouter, { children: _jsxs(QueryClientProvider, __assign({ client: queryClient }, { children: [_jsx(GoogleOAuthProvider, __assign({ clientId: "981924406162-llg4arbcva1i69uao2u6l29ajjo26lpj.apps.googleusercontent.com" }, { children: _jsx(Provider, __assign({ store: store }, { children: _jsx(LocalizationProvider, { children: _jsx(HelmetProvider, { children: _jsx(App, {}) }) }) })) })), _jsx(ReactQueryDevtools, { initialIsOpen: true })] })) }));
}
