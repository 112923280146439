// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.topnKx1glDIgXEHDrYCB {
  display: flex;
  flex-direction: column;
  gap: 10px;
  background-color: #ffffff;
  box-shadow: 0px 4px 16px 1px rgba(20, 130, 197, 0.1);
  width: 100%;
  border-radius: 24px;
  padding: 10px 20px;
  margin-top: 2%;
  margin-bottom: 11px;
  position: relative;
}

.UZan6DEv0vseD3Lt7S6q {
  display: flex;
  flex-direction: column;
  overflow: scroll;
}

@media (max-width: 1300px) {
  .topnKx1glDIgXEHDrYCB {
    padding: 10px 20px;
  }
}

@media (max-width: 750px) {
  .topnKx1glDIgXEHDrYCB {
    padding: 10px 20px;
  }
}`, "",{"version":3,"sources":["webpack://./src/pages/CompaniesSearchPage/CompaniesSerchResult/companiesSeractResultBlock.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,sBAAsB;EACtB,SAAS;EACT,yBAAyB;EACzB,oDAAoD;EACpD,WAAW;EACX,mBAAmB;EACnB,kBAAkB;EAClB,cAAc;EACd,mBAAmB;EACnB,kBAAkB;AACpB;;AAEA;EACE,aAAa;EACb,sBAAsB;EACtB,gBAAgB;AAClB;;AAEA;EACE;IACE,kBAAkB;EACpB;AACF;;AAEA;EACE;IACE,kBAAkB;EACpB;AACF","sourcesContent":[".serch_result_block {\r\n  display: flex;\r\n  flex-direction: column;\r\n  gap: 10px;\r\n  background-color: #ffffff;\r\n  box-shadow: 0px 4px 16px 1px rgba(20, 130, 197, 0.1);\r\n  width: 100%;\r\n  border-radius: 24px;\r\n  padding: 10px 20px;\r\n  margin-top: 2%;\r\n  margin-bottom: 11px;\r\n  position: relative;\r\n}\r\n\r\n.search_result_block_scrollable {\r\n  display: flex;\r\n  flex-direction: column;\r\n  overflow: scroll;\r\n}\r\n\r\n@media (max-width: 1300px) {\r\n  .serch_result_block {\r\n    padding: 10px 20px;\r\n  }\r\n}\r\n\r\n@media (max-width: 750px) {\r\n  .serch_result_block {\r\n    padding: 10px 20px;\r\n  }\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"serch_result_block": `topnKx1glDIgXEHDrYCB`,
	"search_result_block_scrollable": `UZan6DEv0vseD3Lt7S6q`
};
export default ___CSS_LOADER_EXPORT___;
